import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
  AtomSpinner,
  Button,
  EmptyContent,
  Form,
  FormOptionList,
  Modal,
 } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { linkQuestion, getEligibleQuestions } from '../actions';
import { useLinkQuestionPending, useQuestionPending, useEligibleQuestions, useEligibleQuestionsPending } from '../actions/selectors';

import CSS from './styles/LinkQuestionModal.module.scss';

const validationSchema = Yup.object().shape({
  choice_task_question_id: Yup.string().required('Required'),
});

export const LinkQuestionModal = ({ task }) => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const notify = useNotify();

  const eligible = useEligibleQuestions(task.id);
  const eligiblePending  = useEligibleQuestionsPending();
  const choiceTaskPending = useQuestionPending();
  const linkPending = useLinkQuestionPending();

  const pending = choiceTaskPending || linkPending;

  useEffect(() => {
    dispatch(getEligibleQuestions(task.id))
      .catch(error => notify.error(format.error(error)));
  // eslint-disable-next-line
  },[]);


  const handleSubmit = (data) => {
    data.building_task_id = task.id;
    dispatch(linkQuestion(task.id, data))
      .then(() => close(false))
      .catch(error => notify.error(format.error(error)));
  };

  if(!eligible || eligiblePending) return <AtomSpinner/>;

  return (
    <Modal>
      <Modal.Title>Link existing question</Modal.Title>
      <Modal.Content className={CSS.content}>
        { eligible.length > 0 ? (
          <Form
            name='linkQuestionForm'
            onSubmit={handleSubmit}
            validation={validationSchema}
          >
            <FormOptionList
              name='choice_task_question_id'
              options={
                eligible?.map(list => ({
                  label: list.title,
                  value: list.id,
                  description: `Used in ${list.building_task_count} other task${list.building_task_count > 1 ? 's' : ''}`,
                }))
              }
            />
          </Form>
        ) : (
          <EmptyContent
            message='No questions to link!'
            className='width-full'
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => close(false)}
          disabled={pending}
        />
        <Button
          text='Link'
          color='primary'
          type='submit'
          form='linkQuestionForm'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
}
