import { getActiveRequest } from './getActiveRequest';
import { getArtifactFields } from './getArtifactFields';
import { getRequestFields } from './getRequestFields';

export const getSummaryFields = (taskable) => {
  const activeArtifact = taskable?.choice_task_decision;
  const activeRequest = getActiveRequest(taskable);
  return activeArtifact ?
    getArtifactFields({
      choice_task_question_title: taskable?.settings?.choice_task_question?.title,
      ...activeArtifact,
    }) :
    getRequestFields(activeRequest);
};
