import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_REVIEW_CHOICE_SELECTION_REQUEST',
  request: (token, action, data={}) => request.post(`/partners/choice-requests/token/${token}/${action}`, data).then(r => get(r,'data.data.choice_request')),
  reducers: {
    success: (state, { response: request, params: [token] }) => ({
      ...state,
      choiceSelections: {
        ...state.choiceSelections,
        [token]: request,
      },
    }),
  },
};

export const {
  reducer,
  action: reviewChoiceSelection,
  usePending: useReviewChoiceSelectionPending,
} = generate.redux(creator);

export const initialState = {};
