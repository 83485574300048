import React, { useState } from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';
import { Icon, Collapsible, FieldList, StatusPill } from '@moved/ui';
import { taskTypes } from '@moved/product';

import { ApprovalActions } from './ApprovalActions';

import CSS from './styles/Approval.module.scss';
import columnCSS from './styles/columns.module.scss';

export const Approval = ({ item, className }) => {
  // HOOKS
  const [active, setActive] = useState(false);

  // get the list of request attributes to show and filter out the icons (this view doesn't expose the icons)
  const attributes = taskTypes[item.task_type].getRequestFields(item.details)
    .map(({ icon, ...attribute }) => attribute);

  return (
    <div className={className}>
      <div className={classNames(CSS.box_top,{[CSS.active]:active})} onClick={e => setActive(!active)}>

        <div className={CSS.icon}>
          <Icon symbol={item.icon_name} />
        </div>

        <div className={columnCSS.building_task_title}>
          <span>{item.building_task_title}</span>
        </div>

        <div className={columnCSS.name}>
          <span>
            {item.tenant_firstname} {item.tenant_lastname}
          </span>
        </div>

        <div className={columnCSS.building_name}>
          {item.building_name}
        </div>

        <div className={columnCSS.unit}>
          {item.address_unit}
        </div>

        <div className={columnCSS.submitted_at}>
          {format.date(item.submitted_at)}
        </div>

        <div className={columnCSS.lease_date}>
          {format.date(item.lease_date)}
        </div>

        <div className={columnCSS.status}>
          <StatusPill status={item.status} className={CSS.pill} />
        </div>

        <div className={CSS.arrow_area}><Icon symbol='Chevron-down' library='navigation' className={CSS.arrow} /></div>

      </div>

      <Collapsible open={active}>
        <div className={CSS.box_bottom}>
          <div className={CSS.attributes}>
          {item.details ? (
            <FieldList list={attributes} />
          ) : (
            <p className={'mt-20 mb-20'}>This task does not yet contain any details.</p>
          )}
          </div>
          {item.status === 'rejected' && item?.details?.notes && (
            <div className={CSS.notes}>
              <h6>Reason for decline:</h6>
              <div className={'mt-10'}>{item?.details?.notes}</div>
            </div>
          )}
          {item.status === 'pending-approval' && (
            <div className={classNames('stackHorizontal gap-12',CSS.actions)}>
              <ApprovalActions
                requestType={item.request_type}
                requestId={item.id}
              />
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  )
};
