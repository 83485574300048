import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_GET_QUESTION_OPTIONS',
  request: (id, params={}) => request.get(`/admins/choice-task-question-options/${id}`, { params }).then(r => r?.data?.data?.choice_task_question_option),
  selector: (state, id) => state?.choiceTaskQuestionOptions?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        choiceTaskQuestionOptions: {
          ...state.choiceTaskQuestionOptions,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useQuestionOptions,
  usePending: useQuestionOptionsPending,
} = generate.redux(creator);

export const initialState = {
  choiceTaskQuestionOptions: {},
};
