import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Button, Form, FormText, Modal } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { createQuestion } from '../actions';
import { useCreateQuestionPending } from '../actions/selectors';

import CSS from './styles/CreateQuestionModal.module.scss';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
});

export const CreateQuestionModal = ({ task }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const pending = useCreateQuestionPending();

  const handleSubmit = (data) => {
    dispatch(createQuestion(task.id,data))
      .then(resp => modal.close(false))
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Modal>
      <Modal.Title>Create new question</Modal.Title>
      <Modal.Content className={CSS.content}>
        <Form
          name='createQuestionForm'
          onSubmit={handleSubmit}
          validation={validationSchema}
          className='stackVertical gap-16'
        >
          <FormText
            label='Title'
            name='title'
          />
          <FormText
            label='Subtitle'
            name='subtitle'
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
          disabled={pending}
        />
        <Button
          text='Create'
          color='primary'
          type='submit'
          form='createQuestionForm'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
}
