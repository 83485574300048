import { format } from '@moved/services';

export const getArtifactFields = artifact => {
  if(!artifact) return [];
  return [
    {
      label: artifact.choice_task_question_title,
      value: artifact.selected_options.map(option => option.display_name).join(', ') ?? 'None selected',
      icon: { symbol: 'Question-circle', library: 'code' },
    },
    {
      label: 'Completed by',
      value: artifact?.created_by_user && format.fullname(artifact.created_by_user),
      icon: { symbol: 'User', library: 'general' },
    },

  ]
};
