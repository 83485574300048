import * as getChoiceSelection from './getChoiceSelection';
import * as reviewChoiceSelection from './reviewChoiceSelection';

export const initialState = {
  ...getChoiceSelection.initialState,
  ...reviewChoiceSelection.initialState,
};

export const reducers = [
  getChoiceSelection.reducer,
  reviewChoiceSelection.reducer,
];
