import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Button, Form, FormText, Modal } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { createQuestionOption } from '../actions';
import { useCreateQuestionOptionPending } from '../actions/selectors';

import CSS from './styles/CreateQuestionOptionModal.module.scss';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
});

export const CreateQuestionOptionModal = ({ question, task }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const pending = useCreateQuestionOptionPending();

  const handleSubmit = (data) => {
    dispatch(createQuestionOption(question.id, task.id, data))
      .then(() => modal.close(false))
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Modal>
      <Modal.Title>Add option</Modal.Title>
      <Modal.Content className={CSS.content}>
        <Form
          name='createOptionForm'
          onSubmit={handleSubmit}
          validation={validationSchema}
          className='stackVertical gap-16'
        >
          <FormText
            label='Option Title'
            name='title'
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
          disabled={pending}
        />
        <Button
          text='Create'
          color='primary'
          type='submit'
          form='createOptionForm'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
}
