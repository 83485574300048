/*
*
*  CodeBlock component powered by `react-syntax-highlighter` library
*  https://github.com/react-syntax-highlighter/react-syntax-highlighter
*
*/
import React, { useRef, useState, useEffect } from 'react';
import { get } from 'lodash';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import theme from 'react-syntax-highlighter/dist/esm/styles/hljs/obsidian';

import XmlBeautify from 'xml-beautify';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '../controls/Button/Button';

import CSS from './CodeBlock.module.scss';

SyntaxHighlighter.registerLanguage('json',json);
SyntaxHighlighter.registerLanguage('xml',xml);

export const CodeBlock = ({ code='', language='xml', showLineNumbers=true, className }) => {
  const element = useRef();
  const [hasScroll, setHasScroll] = useState();
  const [expanded, setExpanded] = useState();
  const [formattedCode, setFormattedCode] = useState(`...loading...`);

  // detect number of lines to handle performance of syntax highlighting or not
  const numberOfLines = typeof formattedCode === 'string' ? formattedCode.split(/\r?\n/).length : 0;

  // any time the code or the language is updated, re-beautify it.
  useEffect(() => {
    if(language === 'json') setFormattedCode(JSON.stringify(JSON.parse(code),null,2));
    else if(language === 'xml') setFormattedCode(new XmlBeautify().beautify(code));
    else setFormattedCode(code);
  },[code,language]);

  // detect if the code block is large enough to need a scroller (then use that to hide/show the expand functionality)
  useEffect(() => {
    const updateShouldScroll = () => {
      const shouldScroll = element.current && element.current.scrollHeight > element.current.clientHeight;
      if(shouldScroll !== hasScroll) setHasScroll(shouldScroll);
    }
    updateShouldScroll(); // run the check
    window.addEventListener('resize',updateShouldScroll); // run the check again anytime the window resizes
    return () => window.removeEventListener('resize',updateShouldScroll); // cleanup listeners
  },[hasScroll]);

  return (
    <div
      className={classNames(
        CSS.blockHolder,
        {
          [CSS.expandable]: hasScroll,
          [CSS.expanded]: expanded,
        },
        className,
      )}
      ref={element}
    >
      { hasScroll && expanded && (
        <div className={CSS.collapser}>
          <Button
            text={'Collapse'}
            size='small'
            color='secondary'
            onClick={() => setExpanded(false)}
          />
        </div>
      )}
      { numberOfLines <= 9999 ? (
        <code className={CSS.codeBlock} style={{ backgroundColor: get(theme,'hljs.background') }}>
          <SyntaxHighlighter
            language={language}
            showLineNumbers={showLineNumbers}
            style={theme}
            customStyle={{ margin: 0 }}
            children={formattedCode}
          />
        </code>
      ) : (
        <code className={CSS.basicBlock} style={{ backgroundColor: get(theme,'hljs.background') }}>
          { formattedCode }
        </code>
      )}
      { hasScroll && (
        <div className={CSS.expander}>
          <Button
            text={expanded ? 'Collapse' : 'Expand'}
            size='small'
            color='secondary'
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      )}
    </div>
  );
};

CodeBlock.propTypes = {
  /** The code to render in the block */
  code: PropTypes.string,
  /** Language to use for syntax highlighting https://github.com/rajinwonderland/react-code-blocks/blob/master/LANGUAGES.md */
  language: PropTypes.string,
  /** Flag to show/hide line numbers in syntax highlighting mode */
  showLineNumbers: PropTypes.bool,
  /** (optional) Class name to append to the block wrapper */
  className: PropTypes.string,
};
