import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CONFIG_LINK_CHOICE_TASK_QUESTION',
  request: (taskId, data={}) => request.post(`/admins/choice-task-question-building-tasks`, data).then(r => r?.data?.data?.choice_task_question_building_task?.choice_task_question),
  reducers: {
    success: (state, { response, params: [taskId] }) => {
      return {
        ...state,
        choiceTaskQuestions: {
          ...state.choiceTaskQuestions,
          [taskId]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useLinkQuestionPending,
} = generate.redux(creator);

export const initialState = {};
