import * as cableInternet from './cable-internet'
import * as choice from './choice'
import * as coi from './coi'
import * as docSubmission from './document-submission';
import * as happycoInspection from './happyco-inspection';
import * as insurance from './insurance';
import * as keys from './keys';
import * as orientation from './orientation';
import * as payMoveInCosts from './pay-move-in-costs';
import * as petRegistration from './pet-registration';
import * as petscreeningVerification from './petscreening-verification';
import * as reserve from './reserve';
import * as utilitySetup from './utility-setup';

export const taskTypes = {
  'cable-internet': cableInternet,
  'choice': choice,
  'coi': coi,
  'document-submission': docSubmission,
  'happyco-inspection': happycoInspection,
  'insurance': insurance,
  'keys': keys,
  'orientation': orientation,
  'pay-move-in-costs': payMoveInCosts,
  'pet-registration': petRegistration,
  'petscreening-verification': petscreeningVerification,
  'reserve': reserve,
  'utility-setup': utilitySetup,
};
