import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_DELETE_CHOICE_TASK_QUESTION_OPTION',
  request: (taskId, questionId, optionId) => request.delete(`/admins/choice-task-questions/${questionId}/options/${optionId}`)
    .then(r => r?.data?.data?.choice_task_question),
  reducers: {
    success: (state, { response, params: [taskId] }) => {
      return {
        ...state,
        choiceTaskQuestions: {
            ...state.choiceTaskQuestions,
            [taskId]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useDeleteQuestionOptionPending,
} = generate.redux(creator);

export const initialState = {};
