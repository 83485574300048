import * as coi from '../coi/actions/reducer';
import * as choice from '../choice/actions/reducer';
import * as docSubmission from '../docSubmission/actions/reducer';
import * as key from '../key/actions/reducer'
import * as orientation from '../orientation/actions/reducer';
import * as payMoveInCosts from '../payMoveInCosts/actions/reducer';
import * as petRegistration from '../petRegistration/actions/reducer';
import * as rentersInsurance from '../rentersInsurance/actions/reducer';
import * as reservation from '../reservation/actions/reducer';
import * as utilitySetup from '../utilitySetup/actions/reducer';
import * as vehicleRegistration from '../vehicleRegistration/actions/reducer'

export const initialState = {
  ...coi.initialState,
  ...choice.initialState,
  ...docSubmission.initialState,
  ...key.initialState,
  ...orientation.initialState,
  ...payMoveInCosts.initialState,
  ...petRegistration.initialState,
  ...rentersInsurance.initialState,
  ...reservation.initialState,
  ...utilitySetup.initialState,
  ...vehicleRegistration.initialState,
};

export const reducers = [
  ...coi.reducers,
  ...choice.reducers,
  ...docSubmission.reducers,
  ...key.reducers,
  ...payMoveInCosts.reducers,
  ...petRegistration.reducers,
  ...orientation.reducers,
  ...rentersInsurance.reducers,
  ...reservation.reducers,
  ...utilitySetup.reducers,
  ...vehicleRegistration.reducers,
];
