import { base } from '../base';

import { getActiveRequest } from './getActiveRequest';
import { getArtifacts } from './getArtifacts';
import { getArtifactFields } from './getArtifactFields';
import { getRequestFields } from './getRequestFields';
import { getSummaryFields } from './getSummaryFields';

export const choiceTask = {
  ...base,
  getActiveRequest,
  getArtifactFields,
  getArtifacts,
  getRequestFields,
  getSummaryFields,
};
