export const getRequestFields = request => {
  if(!request) return [];
  return [
    {
      // the label is the question asked, and unfortunately the title in different locations between consumer and admin app schemas
      label: request.choice_task_question_title ?? request?.settings?.choice_task_question?.title,
      value: request.selected_options.map(option => option.display_name ?? option.title).join(', ') ?? 'None selected',
      icon: { symbol: 'Question-circle', library: 'code' },
    },
    request.status === 'rejected' && {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ].filter(Boolean);
};
