import { QuestionOptionDetails } from './components/QuestionOptionDetails';

  // task specific sub-routes
export const routes = [
  {
    path: '/options/:optionId(\\d+)',
    name: 'QuestionOptionDetails',
    component: QuestionOptionDetails,
    breadcrumb: { label: 'Option details' },
    exact: true,
  },
];
