import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import { Option } from './Option';
import CSS from './OptionList.module.scss';

export const OptionList = ({
  name,
  options=[],
  value,
  isControlled,
  disabled,
  size='large',
  allowMultiple=false,
  onChange,
  className,
}) => {
  // on mount determine if controlled input
  const [selected, setSelected] = useOptionalControl(value ?? (allowMultiple ? [] : null), isControlled);

  useEffect(() => {
    if(allowMultiple && !Array.isArray(selected)) setSelected([selected]);
    else if(!allowMultiple && Array.isArray(selected)) setSelected(selected[0]);
  },[selected, setSelected, allowMultiple]);

  const handleSelect = (data) => {
    if(disabled) return;
    if(allowMultiple) {
      let newSelected;
      if(selected && selected.includes(data)) {
        newSelected = selected.filter(item => item !== data);
      }
      else newSelected = [...selected, data];
      !isControlled && setSelected(newSelected);
      onChange?.({[name]:newSelected});
  }
    else {
      !isControlled && setSelected(data);
      onChange?.({[name]:data});
    }
  };

  const classes = [
    CSS.container,
    size === 'square' ? 'stackHorizontal gap-16' : 'stackVertical gap-12',
    className,
  ];

  return (
    <div className={classNames(...classes)}>
      { options.map(option => (
        <Option
          key={`${name}-${option.key ?? option.value}`}
          name={name}
          allowMultiple={allowMultiple}
          size={size}
          {...option}
          selected={allowMultiple ? (selected ? selected.includes(option.value) : false) : selected === option.value}
          disabled={disabled || option.disabled}
          onChange={handleSelect}
        />
      ))}
    </div>
  );
};

OptionList.propTypes = {
  /** Name to use for the form input (shared for this select group) */
  name: PropTypes.string.isRequired,
  /** List of options for individual select items in this group */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** Value to use for this selectlist input */
    value: PropTypes.any.isRequired,
    /** Label text for the input */
    label: PropTypes.node.isRequired,
    /** (optional) Second line of text */
    description: PropTypes.string,
    /** (optional) icon to display */
    icon: PropTypes.shape({
      library: PropTypes.string,
      symbol: PropTypes.string,
    }),
    /** (optional) additional content for the option */
    extendedContent: PropTypes.node,
    /** (optional) class name to add to the radio component */
    className: PropTypes.string,
    /** (optional) allow any individual options to be disabled */
    disabled: PropTypes.bool,
  })),
  /** Flag to treat as checkboxes vs radio inputs */
  allowMultiple: PropTypes.bool,
  /** Selected option value (only initial value if not controlled) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Flag to disable the entire input */
  disabled: PropTypes.bool,
  /** Size variant to display */
  size: PropTypes.oneOf(['small','large','square']),
  /** onChange handler function */
  onChange: PropTypes.func,
  /** (optional) class name to add to the select group component */
  className: PropTypes.string
};
