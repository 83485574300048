import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_UPDATE_CHOICE_TASK_QUESTION_OPTION',
  request: (id, data={}) => request.patch(`/admins/choice-task-question-options/${id}`, data).then(r => r?.data?.data?.choice_task_question_option),
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        choiceTaskQuestionOptions: {
          ...state.choiceTaskQuestionOptions,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateQuestionOptionPending,
} = generate.redux(creator);

export const initialState = {
  choiceTaskQuestionOptions: {},
};
