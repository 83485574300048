import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { LoaderOverlay, TabSlider, Icon, PopOver } from '@moved/ui';
import { useNotify, format, useQuery, useUser } from '@moved/services';

import { DefaultConfiguration } from '../../../components';
import { Question } from './Question';
import { getQuestion } from '../actions';
import { useQuestion, useQuestionPending } from '../actions/selectors';

import CSS from './styles/Choice.module.scss';

export const Configuration = ({ task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { task_id } = useParams();
  const notify = useNotify();
  const history = useHistory();
  const location = useLocation();
  const view = useQuery('view') || 'general';
  const { hasAbilities } = useUser();

  // Update query string in URL when view is selected
  const setView = (view) => {
    return history.replace({
      pathname: location.pathName,
      search: view ? `?view=${encodeURIComponent(view)}` : '',
    });
  };

  // REDUX
  const question = useQuestion(task_id);
  const pending  = useQuestionPending();

  useEffect(() => {
    if (hasAbilities('ConfigureChoiceTasks')) {
      dispatch(getQuestion(task_id))
        .catch(error => notify.error(format.error(error)));
    }
  // eslint-disable-next-line
  },[]);

  if(pending) return (<LoaderOverlay />);

  const tabs = [
    {
      label: 'General',
      value: 'general'
    },
    hasAbilities('ConfigureChoiceTasks') && {
      label: (
        <div className={CSS.question_tab}>
          <span>Question Details</span>
          {!question?.id && (
            <PopOver
              customClass={CSS.popover}
              customArrowClass={CSS.popover_arrow}
              hideOnScroll={true}
              placement='top'
              trigger={'hover'}
              tooltip={
                <span className={CSS.popover_text}>
                  Requires setup
                </span>
              }
            >
              <Icon symbol='Warning-2-circle-small' library='code' size='16px'/>
            </PopOver>
          )}
        </div>
      ),
      value: 'question'
    },
  ].filter(Boolean);

  return (
    <>
      {pending && (<LoaderOverlay />)}

      <div className={CSS.header}>
        <div className={CSS.title}>
          <h1>{task?.title}</h1>
        </div>
      </div>

      { tabs.length > 1 && (
        <TabSlider
          className={CSS.slider}
          list={tabs}
          active={view}
          callback={view => setView(view)}
        />
      )}

      <div className={CSS.view}>
        {(function() {
          switch(view) {
            case 'question':
              return(<Question task={task} question={question} />);
            default:
              return (<DefaultConfiguration task={task} header={false} />);
          };
        })()}
      </div>
    </>
  );
};
