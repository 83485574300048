import { merge } from 'lodash';

import * as createQuestion from './createQuestion';
import * as createQuestionOption from './createQuestionOption';
import * as deleteQuestionOption from './deleteQuestionOption';
import * as getEligibleQuestions from './getEligibleQuestions';
import * as getQuestion from './getQuestion';
import * as getQuestionOptions from './getQuestionOptions';
import * as linkQuestion from './linkQuestion';
import * as reorderQuestionOptions from './reorderQuestionOptions';
import * as updateQuestion from './updateQuestion';
import * as updateQuestionOption from './updateQuestionOption';

export const reducers = [
  createQuestion.reducer,
  createQuestionOption.reducer,
  deleteQuestionOption.reducer,
  getEligibleQuestions.reducer,
  getQuestion.reducer,
  getQuestionOptions.reducer,
  linkQuestion.reducer,
  reorderQuestionOptions.reducer,
  updateQuestion.reducer,
  updateQuestionOption.reducer,
];

export const initialState = merge(
  createQuestion.initialState,
  createQuestionOption.initialState,
  deleteQuestionOption.initialState,
  getEligibleQuestions.initialState,
  getQuestion.initialState,
  getQuestionOptions.initialState,
  linkQuestion.initialState,
  reorderQuestionOptions.initialState,
  updateQuestion.initialState,
  updateQuestionOption.initialState,
);
