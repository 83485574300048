import { get } from 'lodash';

import { getRequestFields } from './getRequestFields'

export { getRequestFields };

export const getSummaryFields = taskable => {
  const activeRequest = get(taskable,'requests[0]');
  return getRequestFields(activeRequest);
};
