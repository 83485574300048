import React from 'react';

import { EmptyContent, Button } from '@moved/ui';
import { useModal, useUser } from '@moved/services'

import { QuestionForm } from './QuestionForm';
import { CreateQuestionModal } from './CreateQuestionModal';
import { LinkQuestionModal } from './LinkQuestionModal';

import CSS from './styles/Question.module.scss';


export const Question = ({ task, question }) => {
  const modal = useModal();
  const { Can } = useUser();

  const openLinkModal = () => {
    modal.open(
      <LinkQuestionModal task={task} />
    );
  };

  const openCreateModal = () => {
    modal.open(
      <CreateQuestionModal task={task} />
    );
  };

  return (
    <div className={CSS.content}>
    {!question.id ? (
      <Can I='ConfigureChoiceTasks'>
        <h3 className={CSS.title}>
          Question details
        </h3>
        <EmptyContent
          message={'No question yet!'}
          actions={(
            <>
              <Can I='UpdateBuildingTasks'>
                <Button
                  size='small'
                  color='primary'
                  text='Link existing'
                  icon={{symbol:'Attachment#1',library:'general'}}
                  onClick={openLinkModal}
                  className={CSS.link_existing_btn}
                />
              </Can>
              <Can I='CreateChoiceTaskQuestions'>
                <Button
                  size='small'
                  color='secondary'
                  text='Create new'
                  icon={{symbol:'Plus',library:'code'}}
                  onClick={openCreateModal}
                  className={CSS.new_question_btn}
                />
              </Can>
            </>
          )}
          className='width-full'
        />
      </Can>

    ) : (
      <QuestionForm task={task} question={question} />
    )}
    </div>
  );
};
