import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_GET_CHOICE_TASK_QUESTION',
  request: (id, params={}) => request.get(`/admins/building-tasks/${id}/choice-task-question`, { params }).then(r => r?.data?.data?.choice_task_question),
  selector: (state, id) => state?.choiceTaskQuestions?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        choiceTaskQuestions: {
          ...state.choiceTaskQuestions,
          [id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useQuestion,
  usePending: useQuestionPending,
} = generate.redux(creator);

export const initialState = {
  choiceTaskQuestions: {},
};
