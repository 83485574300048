import { base } from './base';

import { addonInterests } from './addonInterests';
import { badges } from './badges';
import { bookMovers } from './bookMovers';
import { cableInternet } from './cableInternet';
import { choiceTask } from './choice';
import { coi } from './coi';
import { deposit } from './deposit';
import { docSubmission } from './docSubmission';
import { happycoInspection } from './happycoInspection';
import { insurance } from './insurance';
import { keys } from './keys';
import { moveOutDetails } from './moveOutDetails';
import { moveOutInspection } from './moveOutInspection';
import { movingPlan } from './movingPlan';
import { orientation } from './orientation';
import { payMoveInCosts } from './payMoveInCosts';
import { petRegistration } from './petRegistration';
import { petscreeningVerification } from './petscreeningVerification';
import { reserve } from './reserve';
import { utilities } from './utilities';
import { utilitySetup } from './utilitySetup';
import { vehicleRegistration } from './vehicleRegistration';

// export a map of the taskTypes for lookup by the task type label
export const taskTypes = {
  'addon-interests': addonInterests,
  'badge-upload': badges,
  'book-movers': bookMovers,
  'cable-internet': cableInternet,
  'choice': choiceTask,
  'coi': coi,
  'deposit': deposit,
  'document-submission': docSubmission,
  'happyco-inspection': happycoInspection,
  'insurance': insurance,
  'keys': keys,
  'move-out-details': moveOutDetails,
  'move-out-inspection': moveOutInspection,
  'moving-plan': movingPlan,
  'orientation': orientation,
  'pay-move-in-costs': payMoveInCosts,
  'pet-registration': petRegistration,
  'petscreening-verification': petscreeningVerification,
  'reserve': reserve,
  'utilities': utilities,
  'utility-setup': utilitySetup,
  'vehicle-registration': vehicleRegistration,
};

// export each taskType helper on its own to just get it directly
export {
  base,
  addonInterests,
  badges,
  bookMovers,
  cableInternet,
  coi,
  choiceTask,
  deposit,
  docSubmission,
  insurance,
  happycoInspection,
  keys,
  moveOutDetails,
  moveOutInspection,
  movingPlan,
  orientation,
  payMoveInCosts,
  petRegistration,
  petscreeningVerification,
  reserve,
  utilities,
  utilitySetup,
  vehicleRegistration,
};
