import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Button,
  DragAndDropArea,
  DragAndDropContext,
  DragAndDropItem,
  EmptyContent,
} from '@moved/ui';
import { format, useNotify, useModal, useUser } from '@moved/services';

import { reorderQuestionOptions } from '../actions';
import { CreateQuestionOptionModal } from './CreateQuestionOptionModal'
import { QuestionOptionListItem } from './QuestionOptionListItem';

import CSS from './styles/QuestionOptions.module.scss';

export const QuestionOptions = ({ question, task }) => {
  // HOOKS
  const dispatch = useDispatch();
  const notify = useNotify();
  const modal = useModal();
  const { hasAbilities, Can } = useUser();

 // Get id from URL
  const { task_id } = useParams();

  const { options = [] } = question;

  const isReadOnly = !hasAbilities('UpdateChoiceTaskQuestionOptions');

  const handleOptionsReorder = ({ source, destination }) => {
    // Quick exit if not a valid reorder
    if (
      !destination || destination.index === source.index // Dropped in the same position
    ) return;

    // Create a new array from the current options
    const newOptions = Array.from(options);
    // Remove the item from its original position
    const [movedOption] = newOptions.splice(source.index, 1);
    // Insert the item at the new position
    newOptions.splice(destination.index, 0, movedOption);

    return dispatch(
      reorderQuestionOptions(question.id, task_id, {
        choice_task_question_option_ids: newOptions.map(option => option.id),
      })
    ).catch(error => notify.error(format.error(error)));
  };

  const openCreateModal = () => {
    modal.open(
      <CreateQuestionOptionModal question={question} task={task} />
    );
  };

  return (
    <div className={CSS.options}>
      <div className={CSS.section_title}>
        <h3>Options</h3>
        <Can I='CreateChoiceTaskQuestionOptions'>
          <div className={CSS.option_actions}>
            <Button
              size='small'
              color='tertiary'
              icon={{ symbol: 'Plus', library: 'code' }}
              text='Add option'
              onClick={openCreateModal}
            />
          </div>
        </Can>
      </div>
      {options.length > 0 ? (
        <DragAndDropContext onDragEnd={handleOptionsReorder} isDragDisabled={isReadOnly}>
          <div className='stackVertical gap-28'>
              <DragAndDropArea id='options' className='stackVertical'>
                { options.map((option,index) => (
                  <DragAndDropItem key={option.id} id={`${option.id}`} index={index} className={CSS.listItem}>
                    <QuestionOptionListItem option={option} />
                  </DragAndDropItem>
                ))}
              </DragAndDropArea>
          </div>
        </DragAndDropContext>

      ) : (
        <EmptyContent
          message={'No options yet!'}
        />
      )}
    </div>
  );
};
