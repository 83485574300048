import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_UPDATE_CHOICE_TASK_QUESTION',
  request: (id, data={}) => request.patch(`/admins/choice-task-questions/${id}`, data).then(r => r?.data?.data?.choice_task_question),
  reducers: {
    success: (state, { response }) => {
      return {
        ...state,
        choiceTaskQuestions: {
          ...state.choiceTaskQuestions,
          [response.id]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateQuestionPending,
} = generate.redux(creator);

export const initialState = {};
