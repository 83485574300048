import React from 'react';
import classNames from 'classnames';

import { Uploader } from '@moved/ui';

export const PolicyUpload = ({ setFiles, fileError, setFileError }) => {

  const handleFileSelection = (files) => {
    setFileError(); // remove any requirement errors
    setFiles(files); // set the file in state
  };

  return (
    <>
      <div className={classNames(
        'labelS marginBottom-8 marginTop-16',
        fileError ? 'contentError' : 'contentSecondary'
      )}>
        Policy Document
      </div>
      <Uploader
        id='insurance_file_urls'
        dzOptions={{
          accept: {
            'image/jpg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc','.docx'],
          },
          multiple: true,
          maxFiles: 10,
        }}
        initialFiles={[]}
        onSelect={handleFileSelection}
        icon={{size:'30px'}}
        error={fileError}
      />
      { fileError && (
        <div className='labelS contentError marginTop-4'>{ fileError }</div>
      )}
    </>
  );
};
