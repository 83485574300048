import { Task, TaskDetails, TaskDetailsBreadcrumb } from './components';
import { routes as choiceRoutes } from './types/choice/routes';
import { routes as documentSubmissionRoutes } from './types/document-submission/routes';
import { routes as orientationRoutes } from './types/orientation/routes';

const routes = [
  {
    path: '/tasks/:task_id(\\d+)',
    name: 'Tasks',
    breadcrumb: { label: TaskDetailsBreadcrumb },
    component: Task,
    restrict: ['abode','client'],
    children: [
      {
        path: '/',
        name: 'TaskDetails',
        component: TaskDetails,
        exact: true,
      },
      // task specific sub-routes
      ...choiceRoutes,
      ...documentSubmissionRoutes,
      ...orientationRoutes,
    ],
  },
];

export default routes;
