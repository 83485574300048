import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { format, useNotify, useUser } from '@moved/services';
import { Card, Icon, PopoverMenu, useDragAndDropItem } from '@moved/ui';

import { deleteQuestionOption } from '../actions';

import CSS from './styles/QuestionOptionListItem.module.scss';


export const QuestionOptionListItem = ({ task, question, option }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();
  const { dragHandleProps, isDragging } = useDragAndDropItem();
  const { hasAbilities, Can } = useUser();
  const [pending, setPending] = useState();

  const isReadOnly = !hasAbilities('UpdateChoiceTaskQuestionOptions');

  const openDetails = e => {
    e.preventDefault();
    return history.push(`${location.pathname}/options/${option.id}`);
  };

  const handleDeleteOption = (e) => {
    e.stopPropagation(); // needed to avoid triggering the whole card's onClick
    if(pending) return;
    setPending(true);
    dispatch(deleteQuestionOption(task.id,question.id,option.id))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  }

  return (
    <Card
      className={classNames(CSS.task,'stackHorizontal',{ [CSS.dragging]: isDragging })}
      onClick={openDetails}
    >
      <div className={classNames(CSS.taskTitle,'stackHorizontal gap-8')}>
        <Can I='UpdateChoiceTaskQuestionOptions'>
          <div className={CSS.dragHandle} {...dragHandleProps} onClick={e => e.stopPropagation()}>
            <Icon symbol='Drag' library='general' />
          </div>
        </Can>
        <Icon className={classNames({ [CSS.icon]: !isReadOnly })} symbol={option?.icon?.symbol} library={option?.icon?.library} />
        <div className='headingS contentPrimary'>{option?.title}</div>
      </div>

      <div className={classNames(CSS.taskButtons,'stackHorizontal gap-16')}>
        <PopoverMenu>
          <PopoverMenu.Trigger onClick={e => e.stopPropagation()} className={CSS.taskButton}>
            <Icon library='general' symbol='Other#2' color='inherit' />
          </PopoverMenu.Trigger>
          <PopoverMenu.Content mountToBody onClick={e => e.stopPropagation()}>
            <PopoverMenu.Item
              label='View details'
              icon={{ library: 'general', symbol: 'Visible' }}
              onClick={openDetails}
            />
            <Can I='DeleteChoiceTaskQuestionOptions'>
              <PopoverMenu.Divider />
              <PopoverMenu.Item
                label='Delete option'
                icon={{ library: 'general', symbol: 'Trash' }}
                onClick={handleDeleteOption}
                className={CSS.delete}
              />
            </Can>
          </PopoverMenu.Content>
        </PopoverMenu>
        <div className={classNames(CSS.taskButton,CSS.taskArrow)}>
          <Icon library='navigation' symbol='Chevron-right' color='inherit' />
        </div>
      </div>
    </Card>
  );
};
