import { generate, request } from '@moved/services';

const creator = {
  namespace: 'APPROVALS_GET_CHOICE_SELECTION_REQUEST',
  request: (token) => request.get(`/partners/choice-requests/token/${token}`).then(r => r.data.data.choice_request),
  selector: (state, token) => state?.choiceSelections?.[token],
  reducers: {
    success: (state, { response, params: [token] }) => ({
      ...state,
      choiceSelections: {
        ...state.choiceSelections,
        [token]: response,
      },
    }),
  }
};

export const {
  reducer,
  action: getChoiceSelection,
  useResource: useChoiceSelection,
  usePending: useChoiceSelectionPending,
} = generate.redux(creator);

export const initialState = {
  choiceSelections: {},
};
