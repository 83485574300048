import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_REORDER_CHOICE_TASK_QUESTION_OPTIONS',
  request: (id, taskID, data={}) => request.post(`/admins/choice-task-questions/${id}/options/reorder`, data).then(r => r?.data?.data?.choice_task_question),
  reducers: {
    success: (state, { response, params: [id, taskID] }) => {
      return {
        ...state,
        choiceTaskQuestions: {
          ...state.choiceTaskQuestions,
          [taskID]: response,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useReorderQuestionOptionsPending,
} = generate.redux(creator);

export const initialState = {};
