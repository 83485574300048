import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isEmpty, cloneDeep } from 'lodash';
import * as Yup from 'yup';

import {
  Button,
  ContentEditor,
  Form,
  FormText,
  Icon,
  IconSelector,
  LoaderOverlay
} from '@moved/ui';
import { format, useModal, useNotify, useUser } from '@moved/services';

import { ChapterPreviewModal } from '../../orientation/components/ChapterPreviewModal';

import { getTaskDetails } from '../../../actions';
import { useTaskDetailsPending } from '../../../actions/selectors';

import { getQuestionOptions, updateQuestionOption } from '../actions';
import { useQuestionOptions, useQuestionOptionsPending, useUpdateQuestionOptionPending } from '../actions/selectors';

import CSS from '../../../components/styles/TaskDetails.module.scss';
import DescriptionCSS from './styles/Description.module.scss';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
});

const _handleReturn = (state, history, property_id, task_id) => {
  const from = state?.from;
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push(`/admin/properties/${property_id}/tasks/${task_id}?view=question`);
};

export const QuestionOptionDetails = () => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();
  const modal = useModal();
  const { hasAbilities, Can } = useUser();

  const isReadOnly = !hasAbilities('UpdateChoiceTaskQuestionOptions');

 // Get move id from URL
  const { optionId, task_id, property_id } = useParams();

  // MOVE DETAILS STATE
  const option = useQuestionOptions(optionId);
  const pending = useTaskDetailsPending();
  const contentsPending = useQuestionOptionsPending();
  const updatePending = useUpdateQuestionOptionPending();

  const [content, setContent] = useState(option?.description);
  const [selectedIcon, setSelectedIcon] = useState(null)

  useEffect(() => {
    Promise.all([
      dispatch(getTaskDetails(task_id)),
      dispatch(getQuestionOptions(optionId)),
    ])
      .catch(error => notify.error(format.error(error)));
  },[task_id, optionId, notify, dispatch]);

  useEffect(() => {
    if (option?.icon) {
      setSelectedIcon(option.icon);
    }
  }, [option])

  const handleSubmit = (data) => {
    const formatData = cloneDeep(data);
    formatData.icon = selectedIcon;
    formatData.description = content;
    return dispatch(updateQuestionOption(optionId, formatData))
      .then(() => {
        notify.default(`Option details updated!`);
        _handleReturn(location.state, history, property_id, task_id);
      })
      .catch(error => notify.error(format.error(error)));
  };

  const showPreviewModal = () => {
    const currentChapter = {content};
    return modal.open(
      <ChapterPreviewModal chapter={currentChapter} />,
    );
  };

  if(isEmpty(option)) return (
    <>
      <Helmet>
        <title>{`Admin Abode Tasks : Moved`}</title>
      </Helmet>
      <LoaderOverlay />
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`${option?.title} Admin Abode Tasks : Moved`}</title>
      </Helmet>

      {(pending || contentsPending) && (<LoaderOverlay />)}

      <div className={CSS.content_top}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            <div className={CSS.back_btn} onClick={e => _handleReturn(location.state, history, property_id, task_id)}>
              <Icon symbol='Arrow-left' library='navigation' size='32px' />
            </div>
            <h1>{option?.title}</h1>
          </div>
        </div>

        <div className={CSS.form}>
          <div className={CSS.attributes}>
            <Form
              name='optionDetailsForm'
              onSubmit={handleSubmit}
              validation={validationSchema}
              className='stackVertical gap-16'
            >
              <FormText
                label='Title'
                name='title'
                value={option?.title}
                disabled={isReadOnly}
                required
              />
              <FormText
                label='Subtitle'
                name='subtitle'
                value={option?.subtitle}
                disabled={isReadOnly}
              />
              <FormText
                label='Display name'
                name='display_name'
                value={option?.display_name}
                disabled={isReadOnly}
              />
            </Form>

            <div className='marginTop-16 width-twentieth'>
              <label className='labelS contentSecondary'>Icon</label>
              { isReadOnly ? (
                <Icon
                  library={option?.icon?.library ?? 'code'}
                  symbol={option?.icon?.symbol ?? 'Radio-off#2'}
                  size='32px'
                />
              ) : (
                <IconSelector
                  initial={option?.icon || {library:'code',symbol:'Radio-off#2'}}
                  onSelect={(icon) => setSelectedIcon(icon)}
                  size={'32px'}
                />
              )}
            </div>

            <div className='stackHorizontal gap-24 marginBottom-20 marginTop-20' style={{alignItems:'center'}}>
              <div style={{flex:'1 1 auto'}}>
                <div className='labelL contentPrimary marginBottom-4'>Description</div>
                <div className='labelS contentSecondary'>
                  Edit the description below.  You can see how the content will appear
                  for users using the "Preview content" button. Legacy cannot be edited,
                  however you may move or delete it.
                </div>
              </div>
              <Button
                color='tertiary'
                size='default'
                onClick={showPreviewModal}
                text='Preview content'
              />
            </div>
            <div className={DescriptionCSS.contentEditor}>
              <ContentEditor
                id={'content-editor'}
                readOnly={isReadOnly}
                disabled={null}
                data={option?.description}
                onChange={setContent}
                minHeight={100}
                autofocus={false}
                logLevel={'ERROR'}
                customConfig={{ uploadUrl: `/admins/buildings/${property_id}/presigned-s3-urls/task-configuration/choice` }}
              />
            </div>

          </div>
        </div>

      </div>

      <Can I='UpdateChoiceTaskQuestionOptions'>
        <div className={CSS.content_bottom}>
          <div className={CSS.buttons}>
            <Button
              text='Save'
              color='primary'
              type='submit'
              form='optionDetailsForm'
              disabled={pending}
            />
            <Button
              text='Discard changes'
              color='secondary'
              onClick={e => {
                e.preventDefault();
                if (updatePending) return;
                return _handleReturn(location.state, history, property_id, task_id);
              }}
              disabled={pending}
            />
          </div>
        </div>
      </Can>

    </>
  );
};
